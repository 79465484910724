<template>
<div>
    <div class="dashboard-widget-tabs dashboard-widget-pages">
        <div class="dashboard-widget-tabs-content padding-m" style="margin: 0;border: 0;padding: 0;">
            <h2>
                <!-- <i class="uil uil-comment-alt-lines"></i> -->
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path class="fa-primary" d="M360 144h-208C138.8 144 128 154.8 128 168S138.8 192 152 192h208C373.3 192 384 181.3 384 168S373.3 144 360 144zM264 240h-112C138.8 240 128 250.8 128 264S138.8 288 152 288h112C277.3 288 288 277.3 288 264S277.3 240 264 240z" />
                    <path class="fa-secondary" d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.75 11.25 15.45 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM264 288h-112C138.8 288 128 277.3 128 264S138.8 240 152 240h112C277.3 240 288 250.8 288 264S277.3 288 264 288zM360 192h-208C138.8 192 128 181.3 128 168S138.8 144 152 144h208C373.3 144 384 154.8 384 168S373.3 192 360 192z" /></svg>
                <span class="page-title-svg">اتاق‌های گفت‌وگو</span>
            </h2>
            <div class="dashboard-widget-h-btns">
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
    </div>
    <div :class="'row tgju-widgets-row profile-post-widgets '+ market_tab +''">
        <div class=" tgju-widgets-block col-12 col-md-12 col-xl-8">
            <div :class="'widget-select widget-select-boxed w-100w '+ discussion_active.text +''">
                <v-select2 v-model="discussion_active" @input="componentKey += 1,setMarketTab(discussion_active.text)" :clearable="false" label="value" :options="discussions">
                    <i class="uil uil-list-ui-alt"></i>
                </v-select2>
            </div>
            <Post type="widget" auth="hide" username="everybody" sendbox="enabled" :subject="discussion_active.text" :title="discussion_active.value" col="" :key="componentKey"></Post>
        </div>
        <div class=" tgju-widgets-block col-12 col-md-12 col-xl-4">
            <div class="tgju-widget last-post light">
                <div class="list-widget  list-row bold list-row-title border-0">
                    <div class="list-col l-title dot head-color no-border">
                        <span>اتاق‌های گفت‌وگو</span>
                    </div>
                    <div class="list-col l-item  head-color">
                        <i class="uil uil-comment-alt-lines"></i>
                    </div>
                </div>
                <div class="tgju-widget-content full-padding">
                    <div v-for="discussion in discussions" :key="discussion.text" :class="'discussion-row ' + discussion.text + (market_tab == discussion.text ? ' active' : '')" @click.prevent="setMarketRow(discussion)">
                        <div class="discussion-icon"></div>
                        <h3>{{discussion.value}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این ویو برای مازول اتاق‌های گفت‌وگو استفاده میشود
// @ is an alias to /src
import Post from '@/components/TgjuPosts/Post.vue'
// import ProfileFriendItems from '@/componentsgit/ProfileFriendItems.vue'
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'Forums',
    data: function () {
        return {
            market_tab: 'tgju-discussion-18',
            componentKey: 0,
            discussion_active: { text: 'tgju-discussion-18', value: 'بازار طلا' },
            discussions: [
                { text: 'tgju-discussion-18', value: 'بازار طلا' },
                { text: 'tgju-discussion-19', value: 'بازار سکه' },
                { text: 'tgju-discussion-20', value: 'بازار ارز' },
                { text: 'tgju-discussion-global-2', value: 'بازار ارزهای دیجیتال' },
                { text: 'tgju-discussion-28', value: 'بازار بورس' },
                { text: 'tgju-discussion-36', value: 'بازار‌های کالایی' },
                { text: 'tgju-discussion-global-1', value: 'بازار‌های سهام' },
                { text: 'tgju-discussion-37', value: 'بازار نفت و انرژی' },
                { text: 'tgju-discussion-global-3', value: 'بازار مبادلات ارزی' },
                { text: 'tgju-discussion-35', value: 'بازار فلزات' },
            ],
        }
    },
    components: {
        Post,
        // ProfileFriendItems,
    },
    mounted() {
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        // این متد برای هندل کردن اکشن تغییر دسته بندی نظرات استفاده میشود
        onChange(event) {
            this.discussion = event.target.value;
            this.componentKey += 1
        },
        // این متد نام کاربری را بر می گرداند
        username() {
            return this.$route.params.username ? this.$route.params.username : this.$store.state.user.username
        },
        // این متد برای تغییر و هایلایت کردند بازار انتخاب شده مورد استفاده قرار میگیرد
        setMarketTab(elm) {
            this.market_tab = elm;
        },
        // این متد جهت هندل کردن روی منوی های سمت چپ اتاق گفتگو
        // که باعث تغییر کانال نظرات میشود
        setMarketRow(elm) {
            this.market_tab = elm.text;
            this.discussion_active = elm;
            this.componentKey += 1
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Forums.vue',
                title: 'اتاق‌های گفت‌و‌گو',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
